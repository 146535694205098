import { RichText } from "components/RichTextWidget";
import { List } from "components/SocialSharing/List";
import {
  Container,
  Heading1,
  Icon,
  PageLink,
  Paragraph,
  RenderIf,
} from "shared/components";
import { VIEW_MODES } from "shared/contexts/ViewMode";

import styles from "./Hero004.module.scss";

export const Hero004 = ({ payload }) => {
  const headline = payload?.headline ?? "";
  const description = payload?.description ?? "";
  const buttonText = payload?.buttonText ?? "";
  const buttonLink = payload?.buttonLink ?? "";

  return (
    <div className={styles.wrapper}>
      <Container>
        <RenderIf viewMode={VIEW_MODES.DESKTOP}>
          <Heading1 className={styles.headline} color="dark">
            {headline}
          </Heading1>
        </RenderIf>
        <div className={styles.block}>
          <List
            appearance="brand"
            className={styles.social}
            buttonClassName={styles.button}
          />
          <RenderIf viewMode={VIEW_MODES.MOBILE}>
            <Heading1 className={styles.headline} color="dark">
              {headline}
            </Heading1>
          </RenderIf>
          <div className={styles.content}>
            <RichText
              value={description}
              className={styles.description}
              color="dark"
            />
            <div className={styles.circle}>
              <PageLink link={buttonLink} className={styles.link}>
                <span className={styles.arrow}>
                  <Icon className={styles.icon} name="large_arrow_right" />
                </span>
                <Paragraph color="brand" appearance="bigger">
                  {buttonText}
                </Paragraph>
              </PageLink>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero004;
